import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Logo from "../components/logo"
import Logo from "../images/logo.png"

import { Container, Grid, Box, Typography, Button } from '@material-ui/core';

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const Stafflogin = () => (
  <Layout>
    <SEO title="Staff Login" description="Staff Login" />
    <ThemeProvider theme={theme}>
   <Container>
   <Box className="centercontent">

     <Grid container alignItems="center" justify="center" style={{ height:'95vh' }}>
       <Grid item xs={12} sm={10} md={8} style={{ color:'#fff' }}>

     <Grid container alignItems="center" justify="center">
     <Grid item xs={12} sm={6} md={5} style={{ color:'#fff' }}>
         <Box  pb={4}>
         {/* <Link to="/"> <Logo/></Link> */}
         <Link to="/">   <img src={Logo} alt="Apollonia Ventures" style={{ width:'100%', height:'auto' }} /> </Link>
        </Box>
        </Grid>
        </Grid>
        <Typography variant="body2" align="center" paragraph>
        Are you a guest?
        </Typography>


<Box align="center" pt={3} pb={5}>
 {/* <Typography variant="contained" color="secondary" className="yes"> Yes </Typography> */}
 <Button variant="contained" color="secondary" style={{ backgroundColor:'transparent', border:'2px solid #fff', cursor:'auto', marginRight:7, fontSize:12 }}> Yes </Button>
 <Link to="/login/">  <Button variant="contained" color="secondary" style={{ backgroundColor:'transparent', border:'2px solid #fff', fontSize:12 }}> staff login </Button></Link> 
  </Box>

  <Typography variant="body2" align="center" paragraph>If you need a guest credential, please contact us at info@apolloniaventures.com</Typography>
       </Grid>
     </Grid>

     </Box>
   </Container>

    </ThemeProvider>
  </Layout>
)

export default Stafflogin
